<template>
  <transition name="modal-fade">
    <div class="modal-container">
      <div class="modal-content align-items-center mx-3">
        <button class="close-button" @click="$emit('close')">×</button>

        <!-- Formulario de Login -->
        <div v-if="isLogin">
          <div class="myform form" v-if="!isLoading && !wrongPassword">
            <div class="logo mb-3">
              <p class="h2 text-primary-blue mb-1 font-weight-normal">
                {{ menuItems.login }}
              </p>
              <p class="text-primary-blue mb-1">{{ menuItems.loginDesc }}</p>
            </div>
            <div class="form-group">
              <label>{{ menuItems.formEmail }}</label>
              <input
                v-model="loginData.email"
                type="email"
                name="email"
                class="form-control"
                :placeholder="menuItems.formEmailPlaceHolder"
              />
            </div>
            <div class="form-group">
              <label>{{ menuItems.formPassword }}</label>
              <input
                v-model="loginData.password"
                type="password"
                name="password"
                class="form-control"
                :placeholder="menuItems.passwordPlaceHolder"
              />
            </div>
            <div class="col-md-12 text-center">
              <button
                type="submit"
                class="btn btn-secondary mt-3"
                @click="submitLogin"
              >
                {{ menuItems.signInBtn }}
              </button>
            </div>
            <div class="col-md-12 text-center my-3">
              <p>
                <a href="javascript:void(0);" @click="toggleForm(false)">
                  {{ menuItems.createAcc }}
                </a>
              </p>
            </div>
          </div>
          <!-- Loader -->
          <div
            class="spinner-border text-primary-green"
            role="status"
            v-if="isLoading && !wrongPassword"
          >
            <span class="sr-only"></span>
          </div>
          <!-- Error: Password o username no existe -->
          <div
            v-if="wrongPassword && !isLoading"
            class="d-flex align-items-center flex-column"
          >
            <img class="img-error" src="/img/img-error.png" alt="error img" />
            <p class="text-primary-blue mb-1 text-center my-3">
              {{ customErrWrongPassword }}
            </p>
            <button class="btn btn-secondary" @click="toggleForm(false)">
              {{ confirm }}
            </button>
          </div>
        </div>

        <!-- Formulario de Signup -->
        <div v-else>
          <div
            class="myform form"
            v-if="
              !successRegister &&
              !isLoading &&
              !errorMsg &&
              !userNameAlreadyTaken
            "
          >
            <div class="logo mb-3">
              <p class="h2 text-primary-blue mb-1 font-weight-normal">
                {{ menuItems.signUp }}
              </p>
              <p class="text-primary-blue mb-1">{{ menuItems.signUpDesc }}</p>
            </div>

            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <!-- Nombre -->
              <ValidationProvider
                rules="required|alpha_spaces"
                :bails="false"
                v-slot="{ errors }"
              >
                <div class="d-flex flex-column mt-3">
                  <label for="Nombre" class="text-small">{{
                    menuItems.formName
                  }}</label>
                  <input
                    type="text"
                    class="form-control rounded"
                    :class="{ 'is-invalid': errors.length }"
                    v-model="signupData.firstName"
                    id="nombre"
                    name="Nombre"
                    :placeholder="menuItems.formNamePlaceHolder"
                  />
                  <span class="text-danger text-small capitalize">{{
                    errors[0]
                  }}</span>
                </div>
              </ValidationProvider>

              <!-- Apellido -->
              <ValidationProvider
                rules="required|alpha_spaces"
                :bails="false"
                v-slot="{ errors }"
              >
                <div class="d-flex flex-column mt-3">
                  <label for="Apellido" class="text-small">{{
                    menuItems.formLastName
                  }}</label>
                  <input
                    type="text"
                    class="form-control rounded"
                    :class="{ 'is-invalid': errors.length }"
                    v-model="signupData.lastName"
                    id="apellido"
                    name="Apellido"
                    :placeholder="menuItems.formLastNamePlaceHolder"
                  />
                  <span class="text-danger text-small capitalize">{{
                    errors[0]
                  }}</span>
                </div>
              </ValidationProvider>

              <!-- Email -->
              <ValidationProvider
                rules="required|email"
                :bails="false"
                v-slot="{ errors }"
              >
                <div class="d-flex flex-column mt-3">
                  <label for="Email" class="text-small">Email</label>
                  <input
                    type="text"
                    class="form-control rounded"
                    :class="{ 'is-invalid': errors.length }"
                    v-model="signupData.email"
                    id="email"
                    name="Email"
                    placeholder="email@gmail.com"
                  />
                  <span class="text-danger text-small capitalize">{{
                    errors[0]
                  }}</span>
                </div>
              </ValidationProvider>

              <!-- Password -->
              <ValidationProvider
                rules="required"
                :bails="false"
                v-slot="{ errors }"
              >
                <div class="d-flex flex-column mt-3">
                  <label for="Password" class="text-small">{{
                    menuItems.formPassword
                  }}</label>
                  <input
                    v-model="signupData.password"
                    type="password"
                    name="password"
                    class="form-control"
                    :placeholder="menuItems.passwordPlaceHolder"
                  />
                  <span class="text-danger text-small capitalize">{{
                    errors[0]
                  }}</span>
                </div>
              </ValidationProvider>

              <!-- Botón de Registro -->
              <div class="col-md-12 text-center mt-3">
                <button
                  type="submit"
                  class="btn btn-secondary mybtn btn-primary tx-tfm"
                  @click="submitSignup"
                  :disabled="invalid"
                >
                  {{ menuItems.signUpBtn }}
                </button>
              </div>

              <!-- Términos y Condiciones -->
              <ValidationProvider
                rules="required"
                :bails="false"
                v-slot="{ errors }"
              >
                <div class="d-flex flex-column mt-3 align-items-center">
                  <div class="d-flex align-items-center">
                    <input
                      type="checkbox"
                      id="scales"
                      name="scales"
                      v-model="signupData.terms"
                      value="yes"
                    />
                    <label for="terms" class="text-small mb-0 ml-2">{{
                      menuItems.terms
                    }}</label>
                    <span class="text-danger text-small capitalize">{{
                      errors[0]
                    }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </ValidationObserver>

            <div class="col-md-12 text-center">
              <p>
                <a href="javascript:void(0);" @click="toggleForm(true)">
                  {{ menuItems.accountQuestion }}
                </a>
              </p>
            </div>
          </div>

          <!-- Success Signup-->
          <div class="myform d-flex flex-column align-items-center form">
            <!-- Loader -->
            <div
              class="spinner-border text-primary-green"
              role="status"
              v-if="isLoading && !successRegister"
            >
              <span class="sr-only"></span>
            </div>

            <div class="logo mb-3" v-else>
              <!-- Registro Exitoso -->
              <div
                v-if="successRegister"
                class="d-flex align-items-center flex-column"
              >
                <img src="/img/img-msg-ok.png" alt="success img" />
                <p
                  class="h2 text-primary-blue mb-1 text-center font-weight-normal"
                >
                  {{ hi }} {{ signupData.firstName }}!
                </p>
                <p class="text-primary-blue mb-1 text-center my-3">
                  {{ accountCreatedSuccefuly }}
                </p>
                <button class="btn btn-secondary" @click="$emit('close')">
                  {{ confirm }}
                </button>
              </div>

              <!-- Error: Email o username ya en uso -->
              <div
                v-else-if="userNameAlreadyTaken"
                class="d-flex align-items-center flex-column"
              >
                <img
                  class="img-error"
                  src="/img/img-error.png"
                  alt="error img"
                />
                <p class="text-primary-blue mb-1 text-center my-3">
                  {{ customErr }}
                </p>
                <button class="btn btn-secondary" @click="toggleForm(true)">
                  {{ confirm }}
                </button>
              </div>

              <!-- Otros errores -->
              <div v-else-if="errorMsg">
                <img
                  class="img-error"
                  src="/img/img-error.png"
                  alt="error img"
                />
                <p
                  class="h2 text-primary-blue mb-1 text-center font-weight-normal"
                >
                  {{ error }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import spanishTexts from "../../scheduleFormEs.json";
import englishTexts from "../../scheduleFormEn.json";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    isLogin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loginData: {
        email: "",
        password: "",
      },
      signupData: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        terms: "",
      },
      isLoading: false,
      successRegister: false,
      userNameAlreadyTaken: false,
      errorMsg: false,
      wrongPassword: false,
    };
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    menuItems() {
      return this.currentLanguage === "es" ? spanishTexts : englishTexts;
    },
    continues() {
      return this.currentLanguage === "es" ? "Continuar" : "Continue";
    },
    hi() {
      return this.currentLanguage === "es" ? "¡Hola" : "Hi";
    },
    accountCreatedSuccefuly() {
      return this.currentLanguage === "es"
        ? "Creaste tu cuenta con éxito"
        : "You have successfully created your account";
    },
    confirm() {
      return this.currentLanguage === "es" ? "Aceptar" : "Confirm";
    },
    customErr() {
      return this.currentLanguage === "es"
        ? "Ups, parece que este correo ya está en uso. Prueba con otro y sigamos adelante."
        : "Oops, it looks like this email is already in use. Try another one and let's keep going.";
    },
    customErrWrongPassword() {
      return this.currentLanguage === "es"
        ? "Ups, parece que este correo o la contraseña que ingresaste no existen, probemos de nuevo"
        : "Oops, it seems like the email or password you entered doesn't exist. Let's try again, shall we?";
    },
    error() {
      return this.currentLanguage === "es"
        ? "Ocurrió un error inesperado"
        : "An unexpected error occurred.";
    },
  },
  mounted() {},
  methods: {
    toggleForm(isLogin) {
      this.$emit("toggle-auth-form", isLogin);
      this.isLoading = false;
      this.successRegister = false;
      this.userNameAlreadyTaken = false;
      this.errorMsg = false;
      this.signupData.firstName = "";
      this.signupData.lastName = "";
      this.signupData.email = "";
      this.signupData.password = "";
      this.signupData.terms = "";
      this.loginData.password = "";
      this.loginData.email = "";
      this.wrongPassword = false;
    },
    submitSignup() {
      this.isLoading = true;

      const signupData = {
        username: this.signupData.firstName + " " + this.signupData.lastName,
        email: this.signupData.email,
        password: this.signupData.password,
      };

      this.$axios
        .post("/auth/local/register", signupData)
        .then((response) => {
          this.sendEmailConfirmation();
          console.log("Registro exitoso:", response.data);
          this.isLoading = false;
          this.successRegister = true;
          this.userNameAlreadyTaken = false;
          this.errorMsg = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (
            error.response.data.error.message ===
            "Email or Username are already taken"
          ) {
            this.userNameAlreadyTaken = true;
            this.errorMsg = false;
          } else {
            console.log("Error desconocido", error);
            this.errorMsg = true;
            this.userNameAlreadyTaken = false;
          }
        });
    },
    submitLogin() {
      this.isLoading = true;

      const loginData = {
        identifier: this.loginData.email,
        password: this.loginData.password,
      };

      this.$axios
        .post("/auth/local", loginData)
        .then((response) => {
          this.isLoading = false;
          this.wrongPassword = false;
          this.errorMsg = false;
          console.log(response);
          this.$store.commit("SET_USER", response.data.user);
          this.$store.commit("SET_AUTH_TOKEN", response.data.jwt);

          if (this.$route.path !== "/reservas") {
            this.$router.push({ name: "reservas" });
          }

          this.$emit("close");
        })
        .catch((error) => {
          console.error("Error al consumir la API:", error);
          this.isLoading = false;

          if (
            error.response.data.error.message ===
              "Invalid identifier or password" ||
            error.response.data.error.message ===
              "identifier is a required field"
          ) {
            this.wrongPassword = true;
            this.errorMsg = false;
            this.isLoading = false;
            console.log(
              "wrongPassword:",
              this.wrongPassword,
              "isLoading:",
              this.isLoading
            );
          } else {
            console.log("Error desconocido", error);
            this.errorMsg = true;
            this.wrongPassword = false;
            this.isLoading = false;
          }
        });
    },
    sendEmailConfirmation() {
      const emailData = {
        email: this.signupData.email,
      };

      this.$axios
        .post("/auth/send-email-confirmation", emailData)
        .then((response) => {
          console.log(response.data);

          this.$emit("close");
        })
        .catch((error) => {
          console.error("Error al consumir la API:", error);
        });
    },
  },
};
</script>

<style scoped>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
}

/* Transiciones suaves */
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease, transform 0.3s ease;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.modal-fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.spinner-border {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  vertical-align: text-bottom;
  border: 0.45em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.img-error {
  width: 100px;
}
</style>
