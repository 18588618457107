<template>
  <div class="bg-primary-blue">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="pt-5 d-flex justify-content-center">
            <img src="/img/isologo-pedal-white.png" alt="Logo" />
          </div>
          <div
            class="our-experiences d-flex flex-column align-items-center pt-5"
          >
            <p class="text-white text-small font-weight-normal mb-0 pb-3">
              {{ menuItems.ourExperience }}
            </p>
            <router-link to="/tour" class="py-1 text-white btn btn-tertiary"
              >E-tour e-xperience</router-link
            >
            <p class="text-white text-small font-weight-normal mb-0 py-3">
              {{ menuItems.location }}
            </p>
            <p class="text-white text-center mb-0 pb-3 h6">
              Blvd. Macacha Güemes 351
              <span class="d-block py-1">Hotel Hilton Pto Madero</span>
              <span class="d-block py-1">CABA.</span>
            </p>
            <a href="tel:+54 9 112345678" class="text-white pb-1 pt-3"
              >+54 9 112345678</a
            >
            <a href="mailto:info@pedalexperience.com" class="text-white py-1"
              >info@pex.com.ar</a
            >
            <div class="mt-4 social-media d-flex flex-column">
              <div class="d-flex align-items-center justify-content-center">
                <div
                  class="social-icon d-flex align-items-center justify-content-center mx-1"
                >
                  <img src="/img/icon-instagram.png" alt="Icon" />
                </div>
              </div>
              <p class="text-white py-4">
                Copyright © 2024, Pedal experience, Inc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import spanishTexts from "../../footerEs.json";
import englishTexts from "../../footerEn.json";

export default {
  name: "NavMenu",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    menuItems() {
      return this.currentLanguage === "es" ? spanishTexts : englishTexts;
    },
  },
  methods: {
    ...mapActions(["setLanguage"]),
    changeLanguage(lang) {
      this.setLanguage(lang);
    },
  },
};
</script>

<style>
.social-icon {
  background: #dcdee6;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.social-icon-dark {
  background: transparent;
  border: 1px solid #dcdee6;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
</style>
