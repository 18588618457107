<template>
  <div id="app">
    <nav-menu></nav-menu>
    <router-view />
    <footer-component></footer-component>
  </div>
</template>

<script>
import NavMenu from "@/views/NavMenu.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default {
  components: {
    NavMenu,
    FooterComponent,
  },
};
</script>
