import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: "es",
    user: null,
    authToken: null,
    pickedExperience: "",
    status: "pendiente",
    date: "",
    timeSlot: "",
    formName: "",
    formLastName: "",
  },
  mutations: {
    SET_LANGUAGE(state, lang) {
      state.language = lang;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_AUTH_TOKEN(state, token) {
      state.authToken = token;
    },
    SET_PICKED_EXPERIENCE(state, payload) {
      state.pickedExperience = payload;
    },
    SET_DATE(state, payload) {
      state.date = payload;
    },
    SET_TIME_SLOT(state, payload) {
      state.timeSlot = payload;
    },
    SET_FORM_NAME(state, firstName) {
      state.formName = firstName;
    },
    SET_FORM_LAST_NAME(state, lastName) {
      state.formLastName = lastName;
    },
  },
  actions: {
    setLanguage({ commit }, lang) {
      commit("SET_LANGUAGE", lang);
    },
    setNames({ commit, state }) {
      if (state.user && state.user.username) {
        let nameParts = state.user.username.split(" ");
        let firstName = nameParts[0];
        let lastName = nameParts[1] || "";
        commit("SET_FORM_NAME", firstName);
        commit("SET_FORM_LAST_NAME", lastName);
      }
    },
  },
  getters: {
    currentLanguage: (state) => state.language,
    isAuthenticated: (state) => state.authToken,
  },
  getUser(state) {
    return state.user;
  },
  getAuthToken(state) {
    return state.authToken;
  },
});
