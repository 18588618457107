<template>
  <div
    class="pt-3 pb-5 d-flex"
    :class="{
      'flex-column align-items-center align-items-lg-start':
        cardOrientation === 'column',
      'overflow-auto': cardOrientation !== 'column',
    }"
  >
    <div
      class="bg-primary-green product-card m-2 d-flex flex-column h-100"
      v-for="product in cardsData"
      :key="product.id"
    >
      <div
        class="d-flex flex-column card-logo-container"
        :style="{ backgroundImage: `url(${product.img})` }"
        v-if="product.img"
      >
        <div class="logo-card mb-2 mr-2 bg-primary-green">
          <img class="p-2" :src="product.logo" alt="Title" />
        </div>
      </div>
      <div class="card-description h-100 d-flex flex-column bg-white rounded">
        <div class="p-2">
          <p class="font-weight-normal h5 mt-2 mb-0">{{ product.name }}</p>
          <p class="font-weight-normal mb-0" v-if="product.ride">
            {{ product.ride }}
          </p>
          <p class="mb-0 text-primary-blue">
            {{ product.description }}
          </p>
        </div>
        <div
          class="product-description mx-1 p-2 d-flex flex-column align-items-center"
          v-if="product.price != ''"
        >
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column testi">
              <p class="mb-0 h5 font-weight-normal text-primary-blue">
                {{ startingAt }}
              </p>
              <p class="mb-0 h5 font-weight-normal text-primary-blue">
                USD {{ product.price }}
              </p>
            </div>
            <div class="d-flex flex-column teston">
              <p class="h5 font-weight-normal mb-1 text-primary-blue">
                {{ whatsIncluded }}
              </p>
              <div class="d-flex align-items-center my-1">
                <img
                  :src="product.icons.firstBenefitIcon"
                  class="product-description-icons mr-2"
                  alt="icon"
                />
                <p class="text-small font-weight-regular mb-0">
                  {{ product.include.firstBenefit }}
                </p>
              </div>
              <div class="d-flex align-items-center my-1">
                <img
                  :src="product.icons.secondBenefitIcon"
                  class="product-description-icons mr-2"
                  alt="icon"
                />
                <p class="text-small font-weight-regular mb-0">
                  {{ product.include.secondBenefit }}
                </p>
              </div>
              <div class="d-flex align-items-center my-1">
                <img
                  :src="product.icons.thirdBenefitIcon"
                  class="product-description-icons mr-2"
                  alt="icon"
                />
                <p class="text-small font-weight-regular mb-0">
                  {{ product.include.thirdBenefit }}
                </p>
              </div>
            </div>
          </div>
          <div class="hour-and-tour d-flex justify-content-end teston">
            <div class="d-flex align-items-center my-1 mr-2">
              <img
                :src="product.icons.distanceIcon"
                class="product-description-icons mr-2"
                alt="icon"
              />
              <p class="font-weight-normal mb-0">
                {{ product.include.distance }}
              </p>
            </div>
            <div class="d-flex align-items-center my-1">
              <img
                :src="product.icons.timeIcon"
                class="product-description-icons mr-2"
                alt="icon"
              />
              <p class="font-weight-normal mb-0">
                {{ product.include.time }}
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center my-2">
          <router-link
            :to="product.ctaLink"
            class="btn-secondary"
            v-if="product.ctaLink"
            >{{ product.cta }}</router-link
          >
          <button
            class="btn-secondary"
            v-else
            @click="selectedProduct(product)"
          >
            {{ product.cta }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CardsComponent",
  props: {
    cardsData: {
      type: Array,
      required: true,
    },
    cardOrientation: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    startingAt() {
      return this.currentLanguage === "es" ? "Precio desde" : "Starting at";
    },
    whatsIncluded() {
      return this.currentLanguage === "es"
        ? "¿Qué Incluye?"
        : "What's included?";
    },
  },
  methods: {
    selectedProduct(product) {
      this.$emit("selected-product", product);
    },
  },
};
</script>

<style>
.card-logo-container {
  background-repeat: no-repeat;
  background-size: cover;
}
.logo-card {
  display: flex;
  align-self: end;
  margin-top: auto;
  width: 100px;
  border-radius: 8px;
}
.product-card {
  border: 2px solid var(--color-primary-blue);
  min-width: 355px;
  border-radius: 12px;
}
.product-description {
  background: #dcdee6;
  border-radius: 16px;
}
.date-card-container:hover {
  cursor: pointer;
}
.card-title {
  border-radius: 12px 12px 0 0;
}
.card-description {
  border-radius: 0 0 12px 12px;
}
.tour-description {
  font-size: 16px;
  color: var(--color-primary-blue);
}
.card-img img {
  max-width: 100%;
}
.h-800 {
  height: 685px;
}
.card-logo-container {
  min-height: 200px;
}
.product-description-icons {
  width: 14px;
  height: 14px;
}
.testi {
  width: 35%;
}
.teston {
  width: 65%;
}
</style>
